import { ClientComponent } from "js-client-widget";
//import { ClientComponent } from "... my local path to ... \\client-widget-bootstrap\\src\\index.js";


class HapimapEditor extends ClientComponent {

  constructor(param={}) {
    // call parent constructor
    super(param)
    // set custom parameters
    this.baseURL = (typeof param.baseURL === "string" ? param.baseURL : 'https://hapimap.mmcreation.com').replace(/\/$/,'');
  }

  open(data) {
    var widgetOptions = {
      url: `${this.baseURL}/editor2`,
      height: window.screen.height * 0.8 > 1080 ? 1080 : window.screen.height * 0.8,
      width: window.screen.width * 0.8 > 1500 ? 1500 : window.screen.width * 0.8,
    };
    // Calling the original class open function using the alias
    this.___open(widgetOptions,data)
  }
}
window.HapimapEditor = HapimapEditor;